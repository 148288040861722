//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-548:_6008,_4058,_7632,_9076,_9332,_4120,_1752,_5904;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-548')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-548', "_6008,_4058,_7632,_9076,_9332,_4120,_1752,_5904");
        }
      }catch (ex) {
        console.error(ex);
      }