//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-548:_1004,_3644,_5240,_1108,_5416,_6228,_6672,_4266;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-548')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-548', "_1004,_3644,_5240,_1108,_5416,_6228,_6672,_4266");
        }
      }catch (ex) {
        console.error(ex);
      }