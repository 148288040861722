//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-548:_2712,_5707,_768,_6400,_3540,_5536,_228,_3836;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-548')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-548', "_2712,_5707,_768,_6400,_3540,_5536,_228,_3836");
        }
      }catch (ex) {
        console.error(ex);
      }